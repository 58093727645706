window.$ = window.jQuery = require('jquery');
require('jquery-datetimepicker');
import objectFitVideos from 'object-fit-videos';
import objectFitImages from 'object-fit-images';

import home from './_home';
import forms from './_forms';
import buttons from './_buttons';
import shopCheckout from './_checkout';

$(function() { // Shorthand for $( document ).ready()
    "use strict";

    window.Home = new home();
    Home.init();
    window.Forms = new forms();
    Forms.init();
    window.Buttons = new buttons();
    Buttons.init();
    window.Checkout = new shopCheckout();

    $(document).ready(function() {
        $('html').addClass('ready');

        // Show alert for IE
        function msieversion() {

            var ua = window.navigator.userAgent;
            var msie = ua.indexOf("MSIE ");

            if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))  // If Internet Explorer, return version number
            {
                //alert(parseInt(ua.substring(msie + 5, ua.indexOf(".", msie))));
                return true;
            }

            return false;
        }
        if (msieversion()) {
            alert("It looks like you are using Internet Explorer.  For the best user experience on this site we recommend using a modern browser such as Google Chrome or Firefox.");
        }
    });

    objectFitVideos();
    objectFitImages('img');
});