"use strict";
require('slick-carousel');

const Home = () => {
    const init = () => {
        $(document).ready(function() {
            let el = document.getElementById('home-slider');
            if (el) {
                bannerSlider(el);
            }
        });
    };

    const bannerSlider = (el) => {
        let $banner = $(el);
        let $slider = $banner.slick({
            infinite: true,
            prevArrow: $('.banner .controls .prev'),
            nextArrow: $('.banner .controls .next'),
            dots: true,
            autoplay: true,
            autoplaySpeed: 5000,
            touchMove: false
        });
    };

    return {
        init:init
    };
};

export default Home;