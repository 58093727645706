"use strict";

const Forms = () => {
    const init = () => {
        $(document).ready(function() {

        });
    };

    const onSuccess = (form) => {
        $(form).find('.alert-success').show();
        $(form).find('.btn-loader').attr('disabled', false);
        $([document.documentElement, document.body]).animate({
            scrollTop: $(form).offset().top - 150
        }, 200);
        $('.btn.btn-loader.loading').removeClass('loading');
    };

    const onError = (form) => {
        $(form).find('.alert-danger').show();
        $(form).find('.btn-loader').attr('disabled', false);
        $([document.documentElement, document.body]).animate({
            scrollTop: $(form).offset().top - 150
        }, 200);
        $('.btn.btn-loader.loading').removeClass('loading');
    };

    return {
        init:init,
        onSuccess:onSuccess,
        onError:onError
    };
};

export default Forms;