"use strict";

const Checkout = () => {
    const initCheckout = () => {
        // Stripe.js instructions taken from https://stripe.com/docs/payments/cards/collecting
        let form = document.getElementById('stripe_payment_form');
        if (form) {
            // Setup stripe.js
            let stripe_publishable_key = $('#stripe_publishable_key').val();
            if (stripe_publishable_key !== '') {
                let stripe = Stripe(stripe_publishable_key);
                let elements = stripe.elements();

                let elementClasses = {
                    focus: 'focused',
                    empty: 'empty',
                    invalid: 'invalid',
                };
                let elementStyles = {
                    base: {
                        color: '#000000',
                        fontSmoothing: 'antialiased',
                        fontSize: '15px',
                        '::placeholder': {
                            color: '#ced4da'
                        }
                    },
                    invalid: {
                        color: '#dc3545',
                        iconColor: '#dc3545'
                    }
                };
                let cardNumber = elements.create('cardNumber', {
                        style: elementStyles,
                        classes: elementClasses,
                    }),
                    cardExpiry = elements.create('cardExpiry', {
                        style: elementStyles,
                        classes: elementClasses,
                    }),
                    cardCvc = elements.create('cardCvc', {
                        style: elementStyles,
                        classes: elementClasses,
                    });

                let valid = {
                    card: false,
                    expiry: false,
                    ccv: false
                };

                function update() {
                    if (valid.card) {
                        if (!valid.expiry) {
                            $('#card_expiry input').focus()
                        } else if (!valid.ccv) {
                            $('#card_cvc input').focus()
                        }
                    } else if (valid.expiry) {
                        if (!valid.cvc) {
                            $('#card_cvc input').focus()
                        } else if (!valid.number) {
                            $('#card_number input').focus()
                        }
                    }
                }

                cardNumber.mount('#card_number');
                cardNumber.on('change', function(event) {
                    if (event.complete) {
                        valid.card = true;
                    } else {
                        valid.card = false
                    }
                    update();
                });

                cardExpiry.mount('#card_expiry');
                cardExpiry.on('change', function(event) {
                    if (event.complete) {
                        valid.expiry = true;
                    } else {
                        valid.expiry = false
                    }
                    update();
                });

                cardCvc.mount('#card_cvc');
                cardCvc.on('change', function(event) {
                    if (event.complete) {
                        valid.ccv = true;
                    } else {
                        valid.ccv = false
                    }
                    update();
                });

                const stripeTokenHandler = (token) => {
                    var hiddenInput = document.createElement('input');
                    hiddenInput.setAttribute('type', 'hidden');
                    hiddenInput.setAttribute('name', 'stripeToken');
                    hiddenInput.setAttribute('value', token.id);
                    form.appendChild(hiddenInput);

                    // Submit the form
                    $('form').request('onCompleteOrder', {
                        data: {
                            stripeToken: token.id
                        },
                        complete: function(data) {
                            $("button[type='submit']").removeClass('loading').attr('disabled', false);
                        }
                    });
                };

                form.addEventListener('submit', function(e) {
                    e.preventDefault();
                    e.stopPropagation();

                    $("button[type='submit']").addClass('loading').attr('disabled', true);

                    let cartName = $(form).find("input[name='cart_name']").val(),
                        tokenData = {
                            name: cartName,
                            address_country: 'AU',
                            country: 'AU'
                        };

                    stripe.createToken(cardNumber, tokenData).then(function(result) {
                        if (result.error) {
                            console.log(result)
                            // Inform the customer that there was an error.
                            var errorElement = document.getElementById('card-errors');
                            errorElement.textContent = result.error.message;

                            $("button[type='submit']").removeClass('loading').attr('disabled', false);
                            scrollToTop();
                        } else {
                            // Send the token to your server.
                            stripeTokenHandler(result.token);
                        }
                    });
                });
            }
        }
    };

    return {
        initCheckout:initCheckout,
    };
};

export default Checkout;