"use strict";

const Buttons = () => {
    const init = () => {
        $(document).ready(function() {
            btnLoader();
        });
    };

    const btnLoader = () => {
        $('.btn.btn-loader').each(function() {
            let $button = $(this);
            // Remove all loading classes to start
            // This is so when an ajax completes, the button can be used again
            $button.removeClass('loading');

            $button.click(function() {
                // First check if it is a submit button
                if ($button.attr("type") === "submit") {
                    // Check if form is valid
                    let form = $button.parents('form')[0];
                    if (form && form.checkValidity()) {
                        // Add loading class on click
                        $button.addClass('loading');
                    }
                } else {
                    // Add loading class on click
                    $button.addClass('loading');
                }
            });
        });
    };

    return {
        init:init,
        btnLoader:btnLoader,
    };
};

export default Buttons;